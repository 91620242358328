import { Attachements } from './../../models/attachements';
import { Room } from '@/models/room';
import {
  FETCH_ROOMS,
  RECEIVE_ROOMS,
  RECEIVE_ROOT,
  SET_ROOM,
  GET_ROOMS,
  GET_ROOT,
  RECEIVE_ROOM_PRODUCTS,
  GET_ROOM_PRODUCTS,
} from './constants';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { roomState } from './types';
import getRooms from '@/api/chat/getRooms';

interface Context {
  commit: Commit;
}

const roomModule: Module<roomState, State> = {
  state: {
    rooms: [],
    root: new Room(),
    products: [],
  },
  mutations: {
    [RECEIVE_ROOMS](store: roomState, rooms: Room[]) {
      store.rooms = rooms;
    },
    [RECEIVE_ROOT](store: roomState, root: Room) {
      store.root = root;
    },
    [RECEIVE_ROOM_PRODUCTS](store: roomState, products: Attachements[]) {
      store.products = products;
    },

    [SET_ROOM](store: roomState, room: Room) {
      // Check if the room already exists
      const existingRoomIndex = store.rooms.findIndex(
        (existingRoom) => existingRoom.id === room.id,
      );

      // If the room doesn't exist, push it to the room state
      if (existingRoomIndex === -1) {
        store.rooms.push(room);
      } else {
        // If the room already exists, you can handle it as per your requirement
        console.log('Room already exists:', room);
        // Or you can update the existing room with new data if needed
        // Example: store.rooms[existingRoomIndex] = room;
      }
    },

    // [SET_ROOM](store: roomState, room: Room) {
    //   store.rooms.push(room);
    // },
  },
  actions: {
    [FETCH_ROOMS]: async (context: Context) => {
      const chats = await getRooms();
      // console.log(chats);
      context.commit(RECEIVE_ROOMS, chats.clients);
      context.commit(RECEIVE_ROOT, chats.root);
    },
  },
  getters: {
    [GET_ROOMS](state: roomState) {
      return state.rooms;
    },
    [GET_ROOT](state: roomState) {
      return state.root;
    },
    [GET_ROOM_PRODUCTS](state: roomState) {
      return state.products;
    },
  },
};

export default roomModule;
