export class User {
  id!: number;
  name!: string;
  avatar!: string;
  email!: string;
  contact!: number | string;
  title!: string;
}
// // import { Role } from './role';

// import { Role } from './role';

// export class User {
//   id!: number;
//   name!: string;
//   email!: string;
//   contact!: number | string;
//   title!: string;
//   avatar!: string;
//   role!: Role;
//   created_at!: Date;
//   updated_at!: Date;
// }
