import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store/index';

//vuetify
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

// ant design
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import Suggestions from 'v-suggestions';
import 'v-suggestions/dist/v-suggestions.css'; // you can import the stylesheets also (optional)

import './assets/styles/custom.css'; // Import your custom CSS file here
require('@/api/subscriber');

import i18n from './i18n'; // Import the i18n configuration

import VueChatScroll from 'vue3-chat-scroll';

loadFonts();
store.dispatch('FETCH_LOGGED_USER');
createApp(App)
  .use(i18n)
  .use(router)
  .use(store, key)
  .use(vuetify)
  .use(Antd)
  .use(Suggestions)
  .use(VueChatScroll)
  .mount('#app');
