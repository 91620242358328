// store.ts
import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';

import categoryModule from './category';
import authModule from './auth';
import registerModule from './register';
import provinceModule from './province';
import productModule from './product';
import branchModule from './branch';
import collaboratorModule from './collaborator';
import roleModule from './role';
import permissionModule from './permission';
import paymentModule from './payment';
import bookingModule from './booking';
import deliveryModule from './delivery';
import chatModule from './chat';
import roomModule from './room';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {
  // You can remove the count state
}
// define injection key
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const key: InjectionKey<Store<any>> = Symbol();

export const store = createStore<State>({
  state: {},
  modules: {
    auth: authModule,
    category: categoryModule,
    register: registerModule,
    province: provinceModule,
    branch: branchModule,
    collaborator: collaboratorModule,
    product: productModule,
    payment: paymentModule,
    booking: bookingModule,
    delivery: deliveryModule,
    role: roleModule,
    permission: permissionModule,
    chat: chatModule,
    room: roomModule,
    // Add other modules here if needed
  },
});
