import { LOGIN_USER, LOGOUT_USER, FETCH_LOGGED_USER } from './constants';
import { Commit, Module } from 'vuex';
import { State } from '..';
import { AuthState } from '../auth/types';
import { User } from '@/models/user';
import getLoggedUser from '@/api/auth/getLoggedUser';
import { AUTH_TOKEN } from '@/constants';
import { Farm } from '@/models/farm';

interface Context {
  commit: Commit;
  state: AuthState;
}

const authModule: Module<AuthState, State> = {
  state: {
    user: new User(),
    farm: new Farm(),
    loggedIn: false,
    token: '',
  },
  mutations: {
    [LOGIN_USER](state: AuthState, token: string) {
      state.loggedIn = true;
      state.token = token;
    },
    [LOGOUT_USER](state: AuthState) {
      state.user = new User();
      state.loggedIn = false;
      state.token = '';
      localStorage.removeItem(AUTH_TOKEN);
    },
    [FETCH_LOGGED_USER](state: AuthState, data: { user: User; farm: Farm }) {
      state.user = data.user;
      state.farm = data.farm;
    },
  },
  actions: {
    // async login({ commit }, { username, password }) {
    //   // eslint-disable-next-line no-useless-catch
    //   try {
    //     // const user = await login(username, password); // Use the login function from fakeApi
    //     // commit('setUser', user);
    //     // commit('setUser', user);
    //   } catch (error) {
    //     throw error;
    //   }
    // },
    [FETCH_LOGGED_USER]: async ({ commit, state }: Context) => {
      const token = localStorage.getItem(AUTH_TOKEN);

      if (token) {
        commit('LOGIN_USER', token);
      }
      // console.log(localStorage.getItem(AUTH_TOKEN));

      if (!state.token) {
        return;
      }

      const user = await getLoggedUser();
      // console.log('the user', user);

      return commit('FETCH_LOGGED_USER', user);
    },
  },
  getters: {
    currentUser: (state) => state.user,
    myFarm: (state) => state.farm,
    isLoggedIn: (state) => state.loggedIn,
  },
};

export default authModule;
